// src/utils/auth.js
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../firebaseConfig';

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      // Check if credential is available
      if (result.credential) {
        const token = result.credential.accessToken;
        console.log(token);
      }

      // The signed-in user info.
      const user = result.user;
      console.log(user);
      // Do something with the user data (e.g., store it in the database)
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    //   const email = error.email;
      console.error('Error during sign-in:', errorCode, errorMessage);
    });
};
