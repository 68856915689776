import '../App.css';
import Navbar from './Navbar'


function App() {
  return (
    <div className="App">
      <Navbar />
    </div>
  );
}

export default App;
