// src/components/SignInButton.js
import React from 'react';
import { signInWithGoogle } from '../utils/auth';

const SignInButton = () => {
  return (
    <button className="login-btn" onClick={signInWithGoogle}>Login</button>

  );
}

export default SignInButton;
