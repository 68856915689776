// Navbar.js
import React from 'react';
import logo from '../imgs/aoc-guilds-logo-PNG.png'; // adjust the path as needed
import SignInButton from './SignInButton';

function Navbar() {
  return (
    <nav className="navbar">
        <a href="/">
            <img src={logo} alt="Logo" className="logo" />
        </a>
        <SignInButton />
    </nav>
  );
}

export default Navbar;